/* eslint-disable react/jsx-filename-extension */
import React from "react";

// import Button from "elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedinIn,
    faXTwitter,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/images/fosoftfooter.png";

export default function Footer() {
    return (
        <footer className="bg-gray-50 text-blue-900 py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-16">
                    <div>
                        <a className="" type="link" href="/">
                            <img
                                src={logo}
                                className="h-24 -ml-4"
                                alt="logo fo soft"
                            />
                        </a>
                    </div>
                    <div>
                        <h1 className="text-lg text-blue-900 font-semibold mb-4">
                            Office
                        </h1>

                        <p className="text-lg font-light">
                            FABRES ORBIS Pvt. Ltd <br />
                            8/260 KUTTIKATTU, KUNNONNI, <br />
                            KOTTAYAM, Kerala, India, 686582
                        </p>
                    </div>
                    <div>
                        <h1 className="text-lg text-blue-900 font-semibold mb-4">
                            Services
                        </h1>
                        <a
                            href="/discuss-project"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            Web Development
                        </a>
                        <a
                            href="/discuss-project"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            Software Development
                        </a>
                        <a
                            href="/discuss-project"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            <p className="text-lg font-light mb-2">
                                UI/UX Development
                            </p>
                        </a>
                        <a
                            href="/discuss-project"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            <p className="text-lg font-light">
                                Game Development
                            </p>
                        </a>
                    </div>
                    <div>
                        <h1 className="text-lg text-blue-900 font-semibold mb-4">
                            Company
                        </h1>
                        <a
                            href="/about"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            About Us
                        </a>
                        <a
                            href="https://forms.gle/avUFCxUJ6UcFqeUN6"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            Join Us
                        </a>
                        <a
                            href="/discuss-project"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            Discuss Projects
                        </a>
                    </div>
                    <div>
                        <h1 className="text-lg text-blue-900 font-semibold mb-4">
                            Contact Us
                        </h1>
                        <a
                            href="mailto:fosoftsolutions@gmail.com"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            fosoftsolutions@gmail.com
                        </a>
                        <a
                            href="mailto:info@fosofts.com"
                            className="block text-lg font-light hover:text-yellow-500 mb-2"
                        >
                            info@fosofts.com
                        </a>
                        <p className="text-lg font-light mb-2">
                            +917012575518
                            <br />
                            +918078871097
                        </p>
                    </div>
                    <div>
                        <h1 className="text-lg text-blue-900 font-semibold mb-4">
                            Social
                        </h1>
                        <a
                            href="https://www.linkedin.com/company/fo-soft-solutions/"
                            type="link"
                            className="flex items-center text-lg font-light hover:text-yellow-500 mb-2"
                            isExternal
                        >
                            <FontAwesomeIcon
                                icon={faLinkedinIn}
                                className="text-blue-500 pr-2"
                                size="1x"
                            />
                            LinkedIn
                        </a>
                        <a
                            href="https://www.instagram.com/fo_softs/"
                            type="link"
                            className="flex items-center text-lg font-light hover:text-yellow-500 mb-2"
                            isExternal
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className="text-pink-500 pr-2"
                                size="1x"
                            />
                            Instagram
                        </a>
                        <a
                            href="https://twitter.com/FoSoft6016"
                            type="link"
                            className="flex items-center text-lg font-light hover:text-yellow-500 mb-2"
                            isExternal
                        >
                            <FontAwesomeIcon
                                icon={faXTwitter}
                                className="text-blue-500 pr-2"
                                size="1x"
                            />
                            Twitter
                        </a>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-700 pt-4 text-center">
                    <p className="text-lg font-light">
                        Copyright 2024 - All rights reserved - FO SOFT SOLUTIONS
                    </p>
                </div>
            </div>
        </footer>
    );
}
