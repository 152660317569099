/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

import Team from 'assets/images/about.gif';

export default function HeroAbout() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight mb-5">
            About Us
          </h1>
          <p className="text-lg font-light mt-4">
            A Division of FO Groups
          </p>
          <p className="font-light text-xl text-gray-500 leading-relaxed">
            FO Soft Solutions is our dedicated IT division for IT
            services and solutions. At this division we offer a
            range of customer-centric services working best for your
            business needs. This includes various types of Software
            and Hardware Development, Web and App Development, SEO
            and Digital Marketing Solutions, UI/UX Designing etc.
          </p>
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={Team} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
