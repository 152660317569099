/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import logo from '../assets/images/fosoftlogo.png';

export default function BrandIcon() {
  return (
  // <Button className="" type="link" href="/">
  //     <p className="text-yellow-theme text-4xl">
  //         FO
  //         <span className="text-blue-900">SOFT</span>
  //     </p>
  // </Button>
    <a className="" type="link" href="/">
      <img src={logo} className="h-16 -ml-4" alt="logo fo soft" />
    </a>
  );
}
