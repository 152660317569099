/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from 'parts/Header';
import Footer from 'parts/Footer';

import JoinUs from 'parts/JoinUs';

export default class JoinUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: '',
        company: '',
        email: '',
        phone: '',
        projectIdea: '',
      },
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

      onChange = (event) => {
        this.setState({
          data: {
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state.data,
            [event.target.name]: event.target.value,
          },
        });
      }

      resetForm = () => {
        this.setState({
          data: {
            name: '',
            company: '',
            email: '',
            phone: '',
            projectIdea: '',
          },
        });
      }

      render() {
        const { data } = this.state;

        return (
          <>
            <Header {...this.props} />
            <JoinUs data={data} onChange={this.onChange} resetForm={this.resetForm} />

            <Footer />
          </>
        );
      }
}
